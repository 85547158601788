import React from 'react'
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import SolidHero from "../../components/solid-hero/solid-hero"
import ContactAndAddressTile from "../../components/contact-and-address-tile/contact-and-address-tile"
import AnmeldungCtaButton from "../../components/anmeldung-cta-button/anmeldung-cta-button"
import ClassOverview from "../../components/class-overview/class-overview"
import ClassOverviewItem from "../../components/class-overview/class-overview-item/class-overview-item"
import TheorieIntensivkursTermine from "../../components/theorie-intensivkurs-termine/theorie-intensivkurs-termine"
import AnmeldungSection from "../../components/anmeldung-section/anmeldung-section"

const KlasseA1Page = ({data}) => (
  <Layout>
    <SEO title="Klasse A1" description={'Müde vom Pedale treten? Dann ab aufs Bike! Mit dem A1 Motorradführerschein geht’s schon ab 16 Jahren aufs nächste Level. Jetzt bei deiner Fahrschule Rauscher.'} />
    <SolidHero imageFluid={data.file.childImageSharp.fluid} imageAlt={'Motorradführerschein Klasse A1'} primary title={'Motorradführerschein'} subtitle={() => <>Klasse A1</>}></SolidHero>
    <AnmeldungCtaButton></AnmeldungCtaButton>
    <ClassOverview multiColumn text={"Der 16. Geburtstag bringt neben einer meist unvergesslichen Party (an die sich manche schon am nächsten Morgen nicht mehr erinnern) verwirrenderweise zwei vollkommen gegensätzliche Dinge mit sich: Einerseits die Möglichkeit, legal Bier zu kaufen – andererseits den A1-Führerschein. Als verantwortungsbewusste Fahrschule bieten wir nur eines davon an, welches kannst du dir hoffentlich denken. Das verursacht jedoch ein völlig neues Freiheitsgefühl, das jeden Rausch um Längen toppt. Überraschung, die Rede ist vom A1, umgangssprachlich 125er-Führerschein. Wie sein Zweitname schon sagt, steht diese Fahrlizenz für Leichtkrafträder mit maximal 125 ccm Hubraum, dazu kommt eine Maximalleistung von 11 kW. Übrigens schließt die Klasse A1 auch einige Trikes sowie alle Möglichkeiten der Klasse AM ein. Diese Klasse (und die mit ihr verbundenen Fahrzeuge) kennt man umgangssprachlich als 50er. Nennst du den A1-Führerschein dein Eigen, stehen dir also schon unter 18 ganz schön viele Möglichkeiten offen. Abfahrt!"}>
      <ClassOverviewItem title={'Ausbildung'}>
        <li>Mindestalter: 16 Jahre</li>
        <li>Ausbildung: Theorie und Praxis</li>
        <li>Prüfung: Theorieprüfung und praktische Prüfung</li>
        <li>Eingeschlossene Klassen: AM</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Stundenpensum'}>
        <li>Übungsstunden: nach Bedarf</li>
        <li>Überlandfahrt: 5</li>
        <li>Autobahnfahrt: 4</li>
        <li>Dunkelfahrt: 3</li>
        <li>Theorie/Doppelstunden á 90 Minuten bei Ersterteilung: 12 (bei Erweiterung nur 6)</li>
        <li>Zusatzstoff: 4</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Vorraussetzungen'}>
        <li>Biometrisches Passfoto</li>
        <li>Sehtest</li>
        <li>Erste-Hilfe-Kurs</li>
        <li>Personalausweis oder Reisepass mit Meldebestätigung</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Merkmale'}>
        <li>... Hubraum max. 125 cm³</li>
        <li>... Leistung max. 11 kW</li>
        <li>... Verhältnis Leistung/Gewicht max. 0,1 kW/kg</li>
      </ClassOverviewItem>

      <ClassOverviewItem title={'Aufstiegs Möglichkeiten'} linkPath={'https://fahrschulerauscher.de/pdf/informationen_klasse_a.pdf'} linkText={'Mehr erfahren'}>
        <li>Motorradführerscheine bieten oft noch Aufstiegsmöglichkeiten zu anderen Klassen. Weiter Infos dazu findest du hier.</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Weitere Infos'} linkPath={"https://tuvsud.com"} linkText={"Zur Website des TÜV"}>
        <li>Weitere Infos zu allen Führerscheinklassen findest du auf der Website des TÜV.</li>
      </ClassOverviewItem>

    </ClassOverview>
    <TheorieIntensivkursTermine/>
    <AnmeldungSection></AnmeldungSection>
    <ContactAndAddressTile></ContactAndAddressTile>
  </Layout>
)

export default KlasseA1Page

export const query = graphql`
  query KlasseA1PageQuery {
    file(relativePath: { eq: "klasse-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
